<template>
    <main class="container " role="main" ref="main">
        <div v-if="!this.loaded" class="s-not-loaded">
            Загрузка, подождите минуточку.<br>
            <img class="s-loading" :src="require('../assets/broken_circle.gif')">
        </div>
        <div v-if="this.loaded && !this.hashExists" class="s-non-existant-hash">
            Неверная ссылка, что-то пошло не так!
        </div>
        <div v-if="this.loaded && this.hashExists && !this.completed" class="s-survey">   
            <div >
                <h2 class="s-header mt-5">Насколько Вы в целом удовлетворены работой РАДАРа по проекту <b class="text-primary">{{ projectName }}</b>?</h2>
                <survey-rate 
                    id="'a1'"
                    :varName="'a1'"
                    :rate="a1"
                    :label="''"
                    :min="1"
                    :max="5"
                    :labelLeft="label.leftLabel"
                    :labelRight="label.rightLabel"
                    :available99="false"
                    :label99="label.rate99"
                    @rate-change="rateChange"
                    ></survey-rate>
                <template v-if='a1 !== null'>
                    <h2 class="a2-header s-header">Насколько Вы удовлетворены работой РАДАРа в рамках данного проекта по следующим характеристикам:</h2>
                    <survey-rate 
                        v-if="a2_1_condition"
                        :id="'a2_1'"
                        :varName="'a2_1'"
                        :rate="a2_1"
                        :label="'Разработка / адаптация анкеты'"
                        :min="1"
                        :max="5"
                        :labelLeft="label.leftLabel"
                        :labelRight="label.rightLabel"
                        :available99="true"
                        :label99="label.rate99"
                        class="mt-5"
                        @rate-change="rateChange"
                        ></survey-rate>
                    <survey-rate 
                        class="mt-5"
                        v-if="a2_2_condition"
                        :id="'a2_2'"
                        :varName="'a2_2'"
                        :rate="a2_2"
                        :label="'Разработка анкеты / гайда'"
                        :min="1"
                        :max="5"
                        :labelLeft="label.leftLabel"
                        :labelRight="label.rightLabel"
                        :available99="true"
                        :label99="label.rate99"
                        @rate-change="rateChange"
                        ></survey-rate>
                    <template v-if="a2_36_condition">
                        <survey-rate 
                            class="mt-5"
                            :id="'a2_3'"
                            :varName="'a2_3'"
                            :rate="a2_3"
                            :label="'Отчет: структура и логика'"
                            :min="1"
                            :max="5"
                            :labelLeft="label.leftLabel"
                            :labelRight="label.rightLabel"
                            :available99="true"
                            :label99="label.rate99"
                            @rate-change="rateChange"
                            ></survey-rate>
                        <survey-rate 
                            class="mt-5"
                            v-if="a2_3 !== null"
                            :id="'a2_4'"
                            :varName="'a2_4'"
                            :rate="a2_4"
                            :label="'Отчет: качество анализа'"
                            :min="1"
                            :max="5"
                            :labelLeft="label.leftLabel"
                            :labelRight="label.rightLabel"
                            :available99="true"
                            :label99="label.rate99"
                            @rate-change="rateChange"
                            ></survey-rate>
                        <survey-rate 
                            class="mt-5"
                            v-if="a2_4 !== null"
                            :id="'a2_5'"
                            :varName="'a2_5'"
                            :rate="a2_5"
                            :label="'Отчет: визуализация данных и дизайн'"
                            :min="1"
                            :max="5"
                            :labelLeft="label.leftLabel"
                            :labelRight="label.rightLabel"
                            :available99="true"
                            :label99="label.rate99"
                            @rate-change="rateChange"
                            ></survey-rate>
                        <survey-rate 
                            class="mt-5"
                            v-if="a2_5 !== null"
                            :id="'a2_6'"
                            :varName="'a2_6'"
                            :rate="a2_6"
                            :label="'Отчет: качество выводов и рекомендаций'"
                            :min="1"
                            :max="5"
                            :labelLeft="label.leftLabel"
                            :labelRight="label.rightLabel"
                            :available99="true"
                            :label99="label.rate99"
                            @rate-change="rateChange"
                            ></survey-rate>


                    </template>
                    <survey-rate 
                        class="mt-5"
                        v-if="a2_7_condition"
                        :id="'a2_7'"
                        :varName="'a2_7'"
                        :rate="a2_7"
                        :label="'Работа менеджера по проекту'"
                        :min="1"
                        :max="5"
                        :labelLeft="label.leftLabel"
                        :labelRight="label.rightLabel"
                        :available99="true"
                        :label99="label.rate99"
                        @rate-change="rateChange"
                        ></survey-rate>
                    <survey-rate 
                        class="mt-5"
                        v-if="a2_7 !== null"
                        :id="'a2_8'"
                        :varName="'a2_8'"
                        :rate="a2_8"
                        :label="'Соблюдение сроков'"
                        :min="1"
                        :max="5"
                        :labelLeft="label.leftLabel"
                        :labelRight="label.rightLabel"
                        :available99="true"
                        :label99="label.rate99"
                        @rate-change="rateChange"
                        ></survey-rate>
                </template>
            </div>
            <div class="a3-container" v-if='a2_8 !== null'>
                <h2 class="s-header">Здесь Вы можете оставить любые комментарии по нашей работе над проектом (о работе менеджера, отчете, сроках и т.д.)</h2>
                <div class="form-group">
                <textarea v-model="a3" placeholder="ОТВЕТ ЗАПИШИТЕ В ПОЛЕ НИЖЕ. МЫ БУДЕМ БЛАГОДАРНЫ ЗА ПОДРОБНЫЕ КОММЕНТАРИИ" class="form-control survey-comment" :disabled="a3_99"></textarea>
                </div>
                <div class="form-check a3-99-container mx-auto">
                <input class="form-check-input" type="checkbox" value="99" id="a3_99" v-model="a3_99">
                <label class="form-check-label" for="a3_99">Затрудняюсь ответить</label>
              </div>
            </div>
            
            <div class="a4-container" v-if="a4_condition">
                <h2 class="s-header ">Оцените, пожалуйста, насколько Вы готовы рекомендовать компанию РАДАР Вашим коллегам по шкале от 0 до 10, где «10» означает, что Вы точно порекомендуете, а «0» - точно не порекомендуете.</h2>
                <survey-rate 
                        :id="'a4'"
                        :varName="'a4'"
                        :rate="a4"
                        :label="''"
                        :min="0"
                        :max="10"
                        :labelLeft="'Точно не\xa0порекомендую'"
                        :labelRight="'Точно порекомендую'"
                        :available99="false"
                        :label99="label.rate99"
                        @rate-change="rateChange"
                        ></survey-rate>
            </div>
            
            <div class="text-center mt-5" v-if="readyToComplete">
                <button class="btn btn-primary" 
                        @click="completeSurvey(true)"
                        :disabled="!readyToComplete"
                        >Готово!</button>
            </div>
        </div>
        <div v-if="this.completed" class="s-survey-complete">
            Большое спасибо за Ваш отзыв. Это поможет нам улучшить качество нашей работы. До свидания!
        </div>
    </main>
</template>


<script>
    import axios from 'axios';
    import SurveyRate from './SurveyRate.vue'
        
    export default {
        data: function () {
            return {
                hashExists: false,
                hash: '',
                reset: false,
                fakeType: null,
                fakeNumber: null,
                loaded: false,
                projectNumber: '',
                projectName: '',
                projectType: 0,
                a1: null,
                a2_1: null,
                a2_2: null,
                a2_3: null,
                a2_4: null,
                a2_5: null,
                a2_6: null,
                a2_7: null,
                a2_8: null,
                a3: '',
                a3_99: false,
                a4: null,  
                completed: false,
                label: {
                    leftLabel: 'Абсолютно не\xa0удовлетворен(-на)',
                    rightLabel: 'Полностью удовлетворен(-на)',
                    bye: "Спасибо большое:)",
                    rate99: "Не сталкивался(-ась) с этим"
                }
            }
        },
        created() {
            const urlParams = new URLSearchParams(window.location.search);
            this.hash = urlParams.get('hash');
            const reset = urlParams.get('reset');
            this.reset = reset !== null && reset !== undefined;
            let fakeType = urlParams.get('fake-type');
            
            if (fakeType !== null && fakeType !== undefined) {
                fakeType = parseInt(fakeType);
                if ((fakeType > 0 && fakeType <= 8) || fakeType === 99) {
                    this.fakeType = fakeType;
                }
            }
            
            const fakeNumber = urlParams.get('fake-number');
            if (fakeNumber !== null && fakeNumber !== undefined && fakeNumber.length > 0) {
                this.fakeNumber = fakeNumber;
            }
            
            if (typeof this.hash === 'string' && this.hash.length > 0) {
                this.fetchData();
            } else {
                this.redirectToSite();
            }
        },
        methods: {
            fetchData: function () {
                let vm = this;
                fetch('./get-data.php' + "?hash=" + this.hash).then((response) => {
                            return response.json().then((json) => {
                                vm.hashExists = vm.hash === json.hash;
                                if (vm.hashExists) {
                                    vm.projectName = json.name;
                                    vm.projectType = json.typeId;
                                    vm.projectNumber = json.number;
                                    if (!this.reset) {
                                        vm.a1 = json.A1;
                                        vm.a2_1 = json.A2_1;
                                        vm.a2_2 = json.A2_2;
                                        vm.a2_3 = json.A2_3;
                                        vm.a2_4 = json.A2_4;
                                        vm.a2_5 = json.A2_5;
                                        vm.a2_6 = json.A2_6;
                                        vm.a2_7 = json.A2_7;
                                        vm.a2_8 = json.A2_8;
                                        vm.a3 = json.A3;
                                        vm.a3_99 = json.A3_99;
                                        vm.a4 = json.A4;
                                        vm.completed = json.completed;
                                    }
                                    if (vm.fakeNumber !== null) {
                                        vm.projectNumber = vm.fakeNumber;
                                    }
                                    if (vm.fakeType !== null) {
                                        vm.projectType = vm.fakeType;
                                    }

                                    vm.loaded = true;
                                    if (vm.completed) {
                                        this.startRedirectTimer();
                                    }
                                }
                            });
                }).catch(function (error) {
                    console.log(error);
                });
            },
            rateChange: function(obj) {
                this[obj.varName] = obj.rate;
                this.scrollToEnd();
            },
            completeSurvey: function(complete = false) {
                let params = new URLSearchParams();
                
                params.append('hash', this.hash);
                params.append('a1', this.a1);
                params.append('a2_1', this.a2_1);
                params.append('a2_2', this.a2_2);
                params.append('a2_3', this.a2_3);
                params.append('a2_4', this.a2_4);
                params.append('a2_5', this.a2_5);
                params.append('a2_6', this.a2_6);
                params.append('a2_7', this.a2_7);
                params.append('a2_8', this.a2_8);
                params.append('a3', this.a3);
                params.append('a3_99', this.a3_99);
                params.append('a4', this.a4);
                params.append('complete', this.readyToComplete);                

                axios({
                    method: 'post', 
                    url: './update-data.php',
                    data: params
                })
                .then(function (response) {
                    // project modified false
                    // processing false
                    console.log(response)
                    
                })
                .catch(function (error) {
                    console.log(error.message);
                });
                
                if (complete && this.readyToComplete) {
                    this.completed = true;
                    this.startRedirectTimer();
                }
            },
            scrollToEnd: function() {    	
                var container = this.$refs['main'];
                container.scrollTop = container.scrollHeight;
            },
            startRedirectTimer: function() {
                setTimeout(this.redirectToSite, 5000);
            },
            redirectToSite: function() {
                window.location.href = "https://radar-research.ru";
            }
        },
        computed: {
            a2_1_condition: function() {
                let cases = [1, 2, 3, 4, 6];
                return cases.indexOf(this.projectType) >= 0;
            },
            a2_2_condition: function() {
                let cases = [5, 7];
                return cases.indexOf(this.projectType) >= 0;
            },
            a2_36_condition: function() {
                // C 3 по 6 вопросы по условию: ЗАДАЕТСЯ ЕСЛИ НОМЕР ПРОЕКТА НАЧИНАЕТСЯ С "A" ИЛИ "Q"
                return (this.numberLetter === "A" || this.numberLetter === "Q")
                    && (this.a2_1 !== null || this.a2_2 !== null || this.projectType >= 8);
            },
            a2_7_condition: function() {
                
                return this.a2_6 !== null
                       || (!this.a2_1_condition && !this.a2_2_condition && !this.a2_36_condition)
                       || (!this.a2_36_condition 
                                && 
                                (
                                    (this.a2_2_condition && this.a2_2 !== null) 
                                    || (!this.a2_2_condition && this.a2_1_condition && this.a2_1 !== null)
                                )
                            );
            },
            a4_condition: function() {
                return (this.a3 !== null && this.a3.trim().length > 1) || this.a3_99;
            },
            numberLetter: function() {
                if (typeof this.projectNumber !== 'string' || this.projectNumber.length < 1) {
                    return '';
                }
                return this.projectNumber.substring(0, 1).toUpperCase();
            },  
            readyToComplete: function() {
                return this.a4 !== null;
            },
        },
        components: {
            SurveyRate,
        }
    };
</script>

<style >
    .s-container {
        max-width: 700px;
        margin: 20px auto;
    }
    .mw-table {
        border: 2px solid #404040;
    }
    .survey-comment {
        height: 100px;
        width: 100%;
        border-radius: 10px;
        border: 1px solid grey;
        margin-top: 15px;
    }
    .s-header {
        font-size: 1.5rem;
        clear: both;
        text-align: left;
    }
    .s-not-loaded, .s-survey-complete {
        font-size: 1.5 rem;
        text-align: center;
    }
    .s-loading {
        margin-top: 20px;
    }
    .a2-header {
        margin-top: 100px;
    }
    .a3-99-container {
        text-align: left;
    }
    .a4-container, .a3-container {
        margin-top: 100px;
    }
</style>
