<template>
    <div class="s-rate-container">
        <div v-if="showLabel" class="s-rate-label text-primary">{{ label }}</div>
        <div class="s-rate-button-container">
            <div class="s-rate-left-label label-wide">{{ labelLeft }}</div>
            <div v-for="item in this.rates"
                 
                class="s-rate-point"
                :class="{ 
                    's-chosen': rate == item,
                    's-rate-point-small': ratesCount > 5
                }"
                :key="varName + item"
                @click="emitRateChange(item)"
                >
                {{ item }}
            </div>
            <div class="s-rate-right-label label-wide">{{ labelRight }}</div>
        </div>
        <div class="s-rate-labels-container label-bottom">
            <div class="s-rate-left-label">{{ labelLeft }}</div>
            <div class="s-rate-right-label">{{ labelRight }}</div>
        </div>
        <div v-if="available99" class="s-rate-99 clearfix">
            <div class="form-check mx-auto s-rate-99-inner">
                <input class="form-check-input" type="checkbox" :id="id99" :checked="checked99" @click="check99">
                <label class="form-check-label" :for="id99">{{ label99 }}</label>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    props: {
        varName: String,
        label: String,
        rate: Number,
        min: Number,
        max: Number,
        labelLeft: String,
        labelRight: String,
        available99: Boolean,
        label99: String,
    },
    data: function(){
        return {
        }
    },
    methods: {
        emitRateChange: function(rate) {
            this.$emit('rate-change', {
                varName: this.varName,
                rate: rate
            });
        },
        check99: function(event) {
            console.log(this.rate); 
            if (this.rate === 99) {
                event.preventDefault();
                console.log(event);
                console.log('prevent');
                return;
            }
            
            this.emitRateChange(99);
        }
    },
    computed: {
        rates: function() {
            let v = [];
            for (let i=this.min; i <= this.max; i++) {
                v.push(i);
            }
            return v;
        },
        ratesCount: function() {
            return this.rates.length;
        },
        checked99: function() {
            return this.rate === 99;
        },
        showLabel: function() {
            return this.label !== null && this.label.length > 0;
        },
        id99: function() {
            return this.varName + '_99'
        },
    },
}
</script>

<style>
    .s-rate-container {
        clear: both;
        width: 100%;
        margin: 0 auto;
    }
    .s-not-chosen {
        
        transition: all 0.5s ease;
        box-shadow: none;
        font-weight: normal;
        color: #000!Important;
        border: 1px solid #000!Important;
        background-color: #FFF;
    }
    .s-chosen {
        transition: all 0.5s ease;
        border: 1px solid green;
        box-shadow: 0px 0px 7px 4px #ccc;
        font-weight: bold;
        color: #FFF !important;
        border: 1px solid #0d6efd!Important;
        background-color: #0d6efd;
    }
    .s-rate-point {
        min-width: 40px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 40px;
        border: 1px solid grey;
        margin: 4px;
    }
    .s-rate-point-small {
        min-width: 27px!Important;
        width: 27px!Important;
        height: 25px!Important;
        line-height: 25px!Important;
        border-radius: 27px!Important;
        margin: 4px 3px;
    }
    .s-rate-button-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
    }
    .s-rate-left-label, .s-rate-right-label {
        font-size: 0.9rem;
        float: left;
        font-weight: bold;
    }
    .label-bottom {
        width: 100%;
    }
    .label-bottom .s-rate-left-label, .label-bottom .s-rate-right-label {
        width: 50%;
    }
    .s-rate-left-label {
        text-align: left;
    }
    .s-rate-right-label {
        text-align: right;
    }
    .s-rate-label {
        font-weight: bold;
        font-size: 1.2rem;
    }
    .s-rate-99 {
        padding-top: 15px;
    }
    .s-rate-99-inner {
        width: 250px;
    }
    .label-bottom {
        display: block;
    }
    .label-wide {
        display: none;
        vertical-align: middle;
    }
    .s-rate-left-label {
        padding-right: 22px;
    }
    .s-rate-right-label {
        padding-left: 22px;
    }
    @media (min-width: 992px) {
        /* md */
        .s-rate-point {
            min-width: 40px;
            width: 40px;
            height: 40px;
            line-height: 40px;
            border-radius: 20px;
            margin: 4px;
        }
        .s-rate-container {
            width: 630px;
            max-width: 630px;
        }
        .label-bottom  {
            display: none;
        }
        .label-wide {
            display: block;
        }
        .s-rate-left-label, .s-rate-right-label {
            font-size: 1rem;
        }
        .s-rate-point-small {
            margin: 4px;
        }
        
    }
</style>
